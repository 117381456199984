import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import LogoTagline from "./LogoTagline"

const Hero = ({ data, pageClassName }) => {
    const { acf } = data.data.wordpressPage || data.data.wordpressWpFood
    const { featured_media } =
        data.data.wordpressPage || data.data.wordpressWpFood
    const { heroContent } = acf || false
    const { path } = data

    return (
        <div
            className={`section section--centered section--hero ${pageClassName}`}
        >
            {featured_media && (
                <Img
                    fixed={featured_media.localFile.childImageSharp.fixed}
                    alt={featured_media.title}
                    style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                    }}
                />
            )}
            <div className="overlay overlay--ink" />
            <div className="overlay overlay--color" />
            <div className="site-width">
                <div className="section__content">
                    {heroContent && (
                        <div
                            className="content"
                            dangerouslySetInnerHTML={{
                                __html: heroContent.replace(
                                    'contenteditable="true"',
                                    ""
                                ),
                            }}
                        />
                    )}
                    {data.data.wordpressWpFood && (
                        <div>
                            <h1>{data.data.wordpressWpFood.title}</h1>
                        </div>
                    )}
                </div>
                {path === "/" && <LogoTagline />}
                {path === "/home" && <LogoTagline />}
            </div>
        </div>
    )
}

Hero.propTypes = {
    pageClassName: PropTypes.string,
    featured_media: PropTypes.object,
    heroContent: PropTypes.string,
    path: PropTypes.string,
}

export default Hero
