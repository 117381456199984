import React, { Component } from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import Logo from "./Logo"
import LogoLandscape from "./LogoLandscape"
import NavItem from "./NavItem"
class Navbar extends Component {
    state = {
        openMenu: false,
    }

    toggleMobileMenu = () => {
        this.setState(
            prevState => ({
                openMenu: !prevState.openMenu,
            }),
            () => {
                if (this.state.openMenu) {
                    document
                        .querySelector(".navbar")
                        .classList.add("navbar--open")
                    document.body.classList.add("no-scroll")
                    document.documentElement.classList.add("no-scroll")
                } else {
                    document
                        .querySelector(".navbar")
                        .classList.remove("navbar--open")
                    document.body.classList.remove("no-scroll")
                    document.documentElement.classList.remove("no-scroll")
                }
            }
        )
    }

    resetScroll = () => {
        this.setState(
            prevState => ({
                openMenu: !prevState.openMenu,
            }),
            () => {
                document
                    .querySelector(".navbar")
                    .classList.remove("navbar--open")
                document.body.classList.remove("no-scroll")
                document.documentElement.classList.remove("no-scroll")
            }
        )
    }

    openModal(e) {
        e.preventDefault()
        const modal = document.getElementById("delivery-modal")
        modal.classList.add("delivery-modal--open")
    }

    render() {
        let { pageID } = this.props

        return (
            <StaticQuery
                query={graphql`
                    query {
                        wordpressWpApiMenusMenusItems(
                            name: { eq: "Primary Menu" }
                        ) {
                            name
                            items {
                                object_id
                                object_slug
                                title
                                url
                                object
                            }
                        }
                        wordpressAcfOptions {
                            options {
                                toast
                                deliveryService {
                                    serviceName
                                    serviceUrl
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <nav className="site-width">
                        <div className="navbar">
                            <div className="navbar__brand">
                                <Link to="/">
                                    <span className="screen-reader-text">
                                        link to homepage
                                    </span>
                                    <LogoLandscape />
                                    <Logo />
                                </Link>
                            </div>
                            <button
                                type="button"
                                onClick={this.toggleMobileMenu}
                                className={
                                    this.state.openMenu
                                        ? "menu-toggle open"
                                        : "menu-toggle"
                                }
                            >
                                <span>Menu</span>
                            </button>
                            <div
                                className={
                                    this.state.openMenu
                                        ? "navbar__menu--mobile open"
                                        : "navbar__menu--mobile"
                                }
                            >
                                <div className="navbar__menu--mobile--items">
                                    {data.wordpressWpApiMenusMenusItems.items.map(
                                        item => {
                                            return (
                                                pageID && (
                                                    <NavItem
                                                        resetScroll={
                                                            this.resetScroll
                                                        }
                                                        key={item.object_id}
                                                        pageID={pageID}
                                                        link={item}
                                                        linkClass="nav-item"
                                                    />
                                                )
                                            )
                                        }
                                    )}
                                </div>
                            </div>
                            <div className="navbar__menu">
                                {data.wordpressWpApiMenusMenusItems.items.map(
                                    item => {
                                        return (
                                            pageID && (
                                                <NavItem
                                                    resetScroll={
                                                        this.resetScroll
                                                    }
                                                    key={item.object_id}
                                                    pageID={pageID}
                                                    link={item}
                                                    linkClass="nav-item"
                                                />
                                            )
                                        )
                                    }
                                )}
                                {data.wordpressAcfOptions.options.toast && (
                                    <a
                                        href={
                                            data.wordpressAcfOptions.options
                                                .toast
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="navbar__cta cta-btn"
                                    >
                                        Pick-up
                                    </a>
                                )}
                                {data.wordpressAcfOptions.options
                                    .deliveryService && (
                                    <button
                                        type="button"
                                        onClick={e => this.openModal(e)}
                                        className="navbar__cta cta-btn"
                                    >
                                        Delivery
                                    </button>
                                )}
                            </div>
                        </div>
                    </nav>
                )}
            />
        )
    }
}

export default Navbar
