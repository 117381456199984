import React, { Component } from "react"

class FixedOrderBtn extends Component {
    componentDidMount() {
        document.addEventListener("scroll", this.trackScrolling)
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.trackScrolling)
    }

    state = {
        orderBtnClasses: "",
        fixedBtnClass: "",
        orderBtnText: "Order Now",
    }

    isBottom = el => el.getBoundingClientRect().bottom < 0

    trackScrolling = () => {
        const hero = document.querySelector(".navbar__brand")

        if (this.isBottom(hero)) {
            this.setState({
                fixedBtnClass: "cta-btn--active-cta",
            })
        } else {
            this.setState({
                orderBtnClasses: "",
                fixedBtnClass: "",
            })
        }
    }

    toggleOrderOptions = () => {
        const { orderBtnClasses } = this.state

        if (orderBtnClasses) {
            this.setState({
                orderBtnClasses: "",
                orderBtnText: "Order Now",
            })
        } else {
            this.setState({
                orderBtnClasses: "order-cta--active",
                orderBtnText: "Order Later",
            })
        }
    }

    openModal(e) {
        e.preventDefault()
        const modal = document.getElementById("delivery-modal")
        modal.classList.add("delivery-modal--open")
    }

    render() {
        const { deliveryService, toast } = this.props.data

        return (
            <button
                className={`cta-btn scrolling-cta cta-btn--fixed ${this.state.fixedBtnClass}`}
                onClick={this.toggleOrderOptions}
            >
                {deliveryService && (
                    <a
                        href="/"
                        onClick={e => this.openModal(e)}
                        className={`order-cta order-cta--delivery ${this.state.orderBtnClasses}`}
                    >
                        <span>Delivery</span>
                    </a>
                )}
                {toast && (
                    <a
                        href={toast}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`order-cta order-cta--toast ${this.state.orderBtnClasses}`}
                    >
                        <span>Pick-up</span>
                    </a>
                )}
                <span>{this.state.orderBtnText}</span>
            </button>
        )
    }
}

export default FixedOrderBtn
