import React from "react"

const Logo = () => {
    const logoStyle = {
        width: "90px",
    }

    return (
        <svg
            className="logo"
            style={logoStyle}
            viewBox="0 0 140 140"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g transform="translate(-3 -3)" fill="none" fillRule="evenodd">
                <circle fill="#FFF" cx="72.5" cy="74" r="69" />
                <path
                    d="M73.5 136c11.88 0 23-3.33 32.47-9.12A62.52 62.52 0 0 0 136 73.5c0-29.9-21.1-54.95-49.2-61.06A62.57 62.57 0 0 0 11 73.5 62.58 62.58 0 0 0 73.5 136zm0-122.96a60.53 60.53 0 0 1 60.46 60.46 60.53 60.53 0 0 1-60.46 60.45A60.53 60.53 0 0 1 13.04 73.5 60.52 60.52 0 0 1 73.5 13.04z"
                    fill="#4D4B4D"
                    fillRule="nonzero"
                />
                <path
                    d="M73 143c13.3 0 25.76-3.73 36.36-10.21A70.02 70.02 0 0 0 143 72.99c0-33.48-23.64-61.54-55.1-68.38A69.88 69.88 0 0 0 73 3C34.4 3 3 34.4 3 73c0 38.59 31.4 70 70 70zM73 5.28A67.8 67.8 0 0 1 140.72 73 67.8 67.8 0 0 1 73 140.7 67.8 67.8 0 0 1 5.28 73C5.28 35.66 35.66 5.28 73 5.28z"
                    fill="#EEBC2A"
                    fillRule="nonzero"
                />
                <path
                    d="M72 75h3.86c5.16-3.32 9.78-4.1 13.68-3.61 3.64.45 6.66 2 8.9 3.61H102c-2.63-2.36-7-5.33-12.66-5.9-4.9-.5-10.77.82-17.34 5.9z"
                    fill="#EEBC2A"
                    fillRule="nonzero"
                />
                <path
                    d="M45.4 73.85a20.78 20.78 0 0 1 8.89-7.38c6.02-2.57 13.39-2.16 21.88 1.12a36.8 36.8 0 0 0-2.77 1.67C59 64.1 50.8 70.8 47.32 74.99h3.1c3.5-3.43 10.12-7.29 20.65-4.08-.8.6-1.59 1.24-2.38 1.92l.02-.07C62.9 70.82 57.37 72.5 53.6 75h5.17c2.39-.8 5.12-1.08 7.98-.41l-.44.41h3.38c5.32-4.68 10.53-7.42 15.57-8.2l.22.13.13-.18a19.38 19.38 0 0 1 5.06-.05c.35.04.7.13 1.04.2l.13.13.1-.1c5.85 1.18 10.3 4.99 13.05 8.07H108c-2.69-3.41-7.85-8.6-15.2-10.23-1.3-1.3-2.6-2.38-3.88-3.26-10.89-7.44-20.96-1.04-23.77 1.09-4.3-.32-8.25.27-11.8 1.8a22.9 22.9 0 0 0-9.46 7.58A24.35 24.35 0 0 0 42 75h2.64c.22-.36.47-.75.75-1.15zm33.57-7.6c-1.09-.47-2.17-.9-3.24-1.29 2.14-.51 4.23-.43 6.25.22-1 .28-2 .64-3 1.06zm9.87-1.98c-1.03-.02-2.07.02-3.1.15-4.23-2.51-8.89-2.72-13.59-.59-1.22-.33-2.42-.6-3.6-.81 3.96-2.38 11.85-5.37 20.29 1.25z"
                    fill="#EEBC2A"
                    fillRule="nonzero"
                />
                <path
                    d="M84.57 73.16c-2.2.33-4.1 1.09-5.57 1.84h15a14.23 14.23 0 0 0-9.43-1.84z"
                    fill="#EEBC2A"
                    fillRule="nonzero"
                />
                <path
                    d="M56.28 104.08v8.1c0 1 .83 1.82 1.85 1.82h32.74c1.02 0 1.85-.82 1.85-1.83v-8.09A34.93 34.93 0 0 0 110 77.01a1.8 1.8 0 0 0-.47-1.4c-.34-.39-.85-.61-1.37-.61H40.84A1.83 1.83 0 0 0 39 77.01a34.93 34.93 0 0 0 17.28 27.07zm25.67 6.27H59.97v-5.54h22.16c.68.88 1.55 2.76-.18 5.54zm7.07 0H86.8v-5.54h2.22v5.54zm12.67-31.7h4.35a31.03 31.03 0 0 1-15.6 22.51h-1.25c9-9.84 11.76-19.14 12.5-22.5zm-53.5 0h46.09c-.89 9.8-5.96 18.8-8.33 22.51H58.56a31.04 31.04 0 0 1-15.6-22.5h5.23z"
                    fill="#4D4B4D"
                    fillRule="nonzero"
                />
                <path
                    d="M58.5 39.42c.12.32.2.65.36.94l.47.9c.08.14.16.31.24.43l.18.32A13.3 13.3 0 0 1 61 44.79c.31.96.5 1.96.53 2.98.1 2.03-.56 4.12-1.71 6.23.48-.32.99-.67 1.44-1.08.41-.44.88-.87 1.2-1.41a8.45 8.45 0 0 0 1.42-3.57c.23-1.3.14-2.63-.14-3.9a12.54 12.54 0 0 0-1.41-3.61l-.27-.43c-.08-.12-.12-.21-.18-.32l-.35-.64c-.15-.2-.2-.44-.3-.66-.09-.23-.22-.45-.27-.68a7.54 7.54 0 0 1-.36-2.84c.12-1.96 1.15-3.99 2.6-5.86-.57.2-1.1.5-1.6.84-.25.17-.48.37-.72.56-.24.2-.45.42-.67.64a8.02 8.02 0 0 0-1.99 7.41c.06.35.2.65.3.97zM83.5 39.42c.12.32.2.65.36.94l.47.9c.08.14.16.31.24.43l.18.32A13.3 13.3 0 0 1 86 44.79c.31.96.5 1.96.53 2.98.1 2.03-.56 4.12-1.71 6.23.48-.32.99-.67 1.44-1.08.41-.44.88-.87 1.2-1.41a8.45 8.45 0 0 0 1.42-3.57c.23-1.3.14-2.63-.14-3.9a12.54 12.54 0 0 0-1.41-3.61l-.27-.43c-.08-.12-.12-.21-.18-.32l-.35-.64c-.15-.2-.2-.44-.3-.66-.09-.23-.22-.45-.27-.68a7.54 7.54 0 0 1-.36-2.84c.12-1.96 1.15-3.99 2.6-5.86-.57.2-1.1.5-1.6.84-.25.17-.48.37-.72.56-.24.2-.45.42-.67.64a8.02 8.02 0 0 0-1.99 7.41c.06.35.2.65.3.97zM70.5 31.42c.12.32.2.65.36.94l.47.9c.08.14.16.31.24.43l.18.32A13.3 13.3 0 0 1 73 36.79c.31.96.5 1.96.53 2.98.1 2.03-.56 4.12-1.71 6.23.48-.32.99-.67 1.44-1.08.41-.44.88-.87 1.2-1.41a8.45 8.45 0 0 0 1.42-3.57c.23-1.3.14-2.63-.14-3.9a12.54 12.54 0 0 0-1.41-3.61l-.27-.43c-.08-.12-.12-.21-.18-.32l-.35-.64c-.15-.2-.2-.44-.3-.66-.09-.23-.22-.45-.27-.68a7.54 7.54 0 0 1-.36-2.84c.12-1.96 1.15-3.99 2.6-5.86-.57.2-1.1.5-1.6.84-.25.17-.48.37-.72.56-.24.2-.45.42-.67.64a8.02 8.02 0 0 0-1.99 7.41c.06.35.2.66.3.97z"
                    fill="#E1E1E1"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}

export default Logo
